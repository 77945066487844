import React from 'react'
import Page from '../../../components/Homemade/AccountPage/PurchaseHistoryItem'
import generatePagePropsOnBuildTime from '../../../helpers/generatePagePropsOnBuildTime'

const UserHistory = (props) => {
    console.log('this is user history')
    return <Page {...generatePagePropsOnBuildTime(props, {
        data: null
    })}/>
}

export default UserHistory